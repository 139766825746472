import React from 'react';
import avatar from './img/briancircle.png';
import schenkerLogo from './img/schenker.jpg';
import crexiLogo from './img/crexi-logo.png';
import colonyGamesLogo from './img/cglogo.png';
//import cashCallLogo from './img/cashcall.png';
import adpLogo from './img/adp.png';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LinkedInLogo from './img/LinkedIn_Logo.png';
import LinkedInWhiteLogo from './img/LinkedIn_Logo_White.png';
import Image from 'react-bootstrap/Image';
import RolandsQuest from './img/WebHeader.png';

class Portfolio extends React.Component {
    render() {
      return (
        <div>
            <div id="title" class="page title headerbackground">
                <div class="headerbox">
                    <div class="shadedborder">
                        <Container className="titleheader">
                        {/* <div class="row titleheader"> */}
                            <Row>
                                <Col xs={12} md={3} className="mugshot_container">
                                    <img src={avatar} alt="Mugshot" class="mugshot" />
                                </Col>
                                <Col xs={12} md={9} className="titleDescription">
                                    <h1>Brian Karcher</h1>
                                    <h2>Experienced Software Architect</h2>
                                    <h3 class="leftalign short_description">
                                        I lead, architect and develop infrastructures, services, and features with an emphasis on enterprise solutions and distributed systems.
                                        <p><a href="mailto:briankarcher90630@gmail.com">briankarcher90630@gmail.com</a></p>
                                        <p><a href="https://www.linkedin.com/in/brian-karcher-a3058432/" target="_blank">
                                            <Image src={LinkedInWhiteLogo} className="LinkedInWhite" />
                                        </a></p>
                                    </h3>
                                </Col>
                            </Row>                        
                        {/* </div> */}
                        </Container>

                            </div>
                        </div>
                        <div class="row">
                            <div class="medium-8 columns">
                                <div class="centerx">

                                </div>
                            </div>
                        </div>

                        <div class="row">

                        </div>
            </div>

            {/* <div id="about" class="skills_section"> */}
            <Card bg="black" text="white" className="skills_section">
                <Card.Body>
                    <Card.Title>
                        <h1>SKILLS</h1>
                    </Card.Title>
                    <Card.Text>
                        <ul>
                            <li>AWS</li>
                            <li>Azure</li>
                            <li>Distributed Systems</li>
                            <li>Cloud Architecture</li>
                            <li>.NET 6</li>
                            <li>Snowflake</li>
                            <li>Airflow</li>
                            <li>Microservices</li>
                            <li>DynamoDB</li>
                            <li>Azure Smart Bots</li>
                            <li>Event-Driven Architecture</li>
                            <li>Elasticsearch</li>
                            <li>C#</li>
                            <li>PostgreSQL</li>
                            <li>Python</li>
                            <li>React</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>


            <Card bk='white' text='black'>
                <Card.Body>
                    <Card.Title>
                        <h1>COMPANIES I HAVE HAD THE PLEASURE TO WORK WITH</h1>
                    </Card.Title>
                    <Card.Text>
                        <Container>
                            <Row>
                                <Col xs={12} md={6}>
                                    <p>
                                        <a href="https://www.crexi.com/" target="_blank">
                                            <p class="centerx">
                                                <img class="clientimage" src={crexiLogo} alt="Schenker Logo" />
                                            </p>
                                            <p class="centerx">
                                                CREXI
                                            </p>
                                        </a>
                                    </p>
                                        <h3 class="centerx">Staff Software Engineer</h3>
                                        <p class="leftalign">
                                            <ul class ="squarelistitems">
                                                <li>Utilized core technologies such as AWS, Snowflake, Airflow, .NET 6, Microservices, DynamoDB, Event-Driven Architecture, Elasticsearch, C#, and PostgreSQL to architect the ETL process flow of several inbound property record files into an integrated system.</li>
                                                <li>Led a team of three engineers to gather analytics from the ETL and create a dynamic page for each property that would show up at the top of Google Search results.</li>
                                                <li>Created documentation, architecture and diagrams for the Snowflake ETL, the Airflow DAG, interfaces between microservices, and the AWS Cloud network to mentor junior developers and ensure the productivity of the team.</li>
                                            </ul>
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <p>
                                            <a href="https://www.dbschenker.com/global" target="_blank">
                                        <p class="centerx">
                                            <img class="clientimage" src={schenkerLogo} alt="Schenker Logo" />
                                        </p>
                                        <p class="centerx">
                                            DB SCHENKER
                                        </p>
                                        </a>
                                        </p>
                                        <h3 class="centerx">Senior Software Engineer</h3>
                                        <p class="leftalign">
                                            I led the New Technologies Team. Among other things, we:
                                            <ul class ="squarelistitems">
                                                <li>Spearheaded the development of Azure Smart Bots and advanced AI techniques to create a self-healing WMS</li>
                                                <li>Designed a Google Glass and Watch application that improved the safety of workers and the speed of warehouse product picking.</li>
                                                <li>Architected an internal cryptocurrency utilizing Blockchain to run a Gamification system and internal economy that improved employee productivity</li>
                                                <li>Created an AI-driven chat bot that employees would use to issue commands to the WMS</li>
                                            </ul>
                                        </p>
                                    </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <p>
                                        <a href="http://colony-games.com/RolansQuest" target="_blank">
                                    <p class="centerx">
                                        <img class="clientimage" src={colonyGamesLogo} alt="CGI Logo" />
                                    </p>
                                    <p class="centerx">
                                        COLONY GAMES INTERACTIVE
                                    </p>
                                    </a>
                                    </p>
                                    <h3 class="centerx">Founder/Architect</h3>
                                    <p class="leftalign">
                                        <ul>
                                            <li>
                                                Created enemy AI using fuzzy logic, behavior trees, state machines, and Utility AI and managed a team of six in developing a top-down action-adventure RPG called Roland's Quest.
                                            </li>
                                            <li>
                                                Ran the marketing and promotion of the game and attended the Game Developers Conference running the floor in promotion of Roland's Quest while managing sprints and leading SCRUMS.
                                            </li>
                                            <li>
                                                Gameplay can be viewed at <a href='https://www.colony-games.com' target='_blank'>https://www.colony-games.com</a>
                                            </li>
                                        </ul>
                                    </p>
                                    <Image src={RolandsQuest} className="client-conent" />
                                    <div class="space"></div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <p>
                                        <a href="http://www.adp.com" target="_blank">
                                    <p class="centerx">
                                        <img class="clientimage" src={adpLogo} alt="ADP Logo" />
                                    </p>
                                    <p class="centerx">
                                        ADP
                                    </p>
                                    </a>
                                    </p>
                                    <h3 class="centerx">Lead Developer</h3>
                                    <p class="leftalign">
                                        I centralized commonalities among states into a common Platform system that speeds up individual state rollouts.
                                        Contributed to constructing a multi-faceted, centralized, multi-tenant enterprise-level software solution utilizing the latest Microsoft and Web technologies.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Text>
                </Card.Body>
            </Card>



            <Card bg='dark' text='white'>
                <Card.Body>
                    <Card.Title>
                        <h1>ABOUT</h1>
                    </Card.Title>
                    <Card.Text>
                        <Container>
                            <Row>
                                <Col>
                                {/* <div class="textcenter"> */}
                                    {/* <p class="nomargin leftalign"> */}
                                        <ul>
                                            <li>
                                            A results-driven, customer-focused, articulate and analytical leader who thinks outside the box; interested in enterprise-scale application development
                                            </li>
                                            <li>
                                            Highly skilled in developing business plans, requirements specifications, stories, user documentation, and architectural systems research
                                            </li>
                                            <li>
                                            Tech Stack: AWS, Azure, .NET 6, Snowflake, React, Airflow, Microservices, DynamoDB, PostgreSQL
                                            </li>
                                            <li>
                                            Strong in design and integration problem solving skills. Expert in React, C#, and .NET with database analysis and design
                                            </li>
                                            <li>
                                            Strong written and verbal communications; a Leader and team player
                                            </li>
                                        </ul>
                                        {/* One of my major passions in life is technology. As a kid, I was intrigued by robots and how software can control them. My elementary school had an Apple IIe with a toy robot that you could control by issuing it commands. Later, at the age of 13, I taught myself Basic. I taught myself C++ during High School by lugging programming books with me in my backpack. */}
                                    {/* </p> */}
                                    {/* <div class="space"></div> */}
                                    {/* <p class="leftalign">
                                        Nowadays, I traverse the field from enterprise systems, to distributed systems, to Artifical Intelligence. I love designing systems and expressing my talents through my work.
                                    </p> */}
                                {/* </div> */}
                                </Col>
                            </Row>
                        </Container>
                    </Card.Text>
                </Card.Body>
            </Card>

            <Card bg='white' text='black'>
                <Card.Body>
                    <Card.Title>
                        <h1>CONTACT</h1>
                    </Card.Title>
                    <Card.Text>
                        <div class="textcenter">
                        <p class="nomargin">You can get in touch with me at <a href="mailto:briankarcher90630@gmail.com">briankarcher90630@gmail.com</a></p>
                        </div>
                        <div class="social_center">
                            <a href="https://www.linkedin.com/in/brian-karcher-a3058432/" target="_blank">
                                <Image src={LinkedInLogo} className="LinkedIn" />
                            </a>

                            {/* <ul class="social_center"> */}
                                {/* <li>
                                    <a onclick="javascript: window.open('https://twitter.com/_BrianKarcher'); return false;" class="icon-twitter" data-icon=""></a>
                                </li> */}
                                {/* <li> */}
                                    {/* <a onclick="javascript:window.open('https://www.linkedin.com/in/brian-karcher-a3058432/');return false;" class="icon-linkedin" data-icon=""></a> */}
                                {/* </li> */}
                            {/* </ul> */}
                        </div>
                        <div class="space"></div>
                    </Card.Text>
                </Card.Body>

            </Card>
        </div>
      );
    }
  }

  export default Portfolio;